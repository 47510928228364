
.marketing-space {
    height: 600px;
    background-color: $secondarycolor; 
    background-image: linear-gradient(to top right, black, $secondarycolor);
    
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                padding-top: 100px;
                padding-bottom: 25px;
                width: 40%;
                max-width: 500px;
                min-width: 250px;
            }
        
    
        .welcome-message {
            color: white;
            font-weight: 500;
            text-align: center;
        }
    
}
.info-grid {
    background-color: #eeeeee;
    display: block;
    align-items: flex-start;
    justify-content: space-evenly;
    
    .info-grid-item {
        margin-top: 20px;

        .info-title {
            font-weight: 500;
            text-align: center;
        }

        .info-card {
            margin-top: 10px;
            margin-bottom: 30px;

            .info-card-title {
                text-align: center;
            }
            
            .info-card-text {
                text-align: left;
            }
            .contact-input {
                margin-top: $text-margin;
                margin-bottom: $text-margin;
                padding-bottom: 5px;
                display: block;
            }
            .card-button {
                align: center;
            }
        }   
    }
}
