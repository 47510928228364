

.custom-drawer {

    .link-list {
        width: 275px;
    }

    .forms {

        .text-field {
            padding-left: 10px;
            padding-right: 10px;
        }

        .custom-button {
            margin-left: 00px;
            margin-right: 00px;
            padding: 10px;
            width: 100%;
            border-radius: 0px;
            display: block;
        }
    }
}