

.hotkey-lister {
    padding-top: 5px;

    .hotkey-holder {
        background-color: black;
        color: white;
        padding: 20px;
        margin-bottom: 10px;
        border-radius: 0px 0px 5px 5px;
        @media screen and (max-width: 797px) {
            display: none;
        }

        .hotkey{
            padding-left: 6px;
            padding-right: 6px;
        }
    }
}

.log-play {

    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;

    .log-play-header {

        padding: 10px 10px 10px 10px;

        .log-play-text{
            display: inline-block;
        }
        .log-play-close {
            float: right;
        }
    }

    .log-play-navigation {
        padding: 10px 10px 10px 10px;
        margin: auto;
        width: 100%;
        
        .back {
            float: left;
        }
        .next {
             float: right;
        }
        
    }
}

.finish-evaluation {
    background-color: white;
}