

.table-holder {
    padding-top: 20px;
    
    .the-table {
        margin: auto;
        width: 90%;
    }
    .sub-table-container {
        width: 100%;
        margin: auto;

            .sub-table {
            // border: 1px solid grey;

            .table-cell {
                font-size: 12px;
            }
        }
    }
    

}
