.appbar{
    margin-top: 57px;
    
}

.appbar-main{
    padding-top: 2px;

    img {
        height: 46px;
        max-width: 80vw;
    }
    
}