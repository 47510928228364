.two-app-bar-container {
    margin-top: 50px;
    .forms {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .forms-title {
        font-weight: 600;
    }
    .game-table {
        margin-top: 20px;
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
    }
    .select-field {
        width: 100%;
    }
}
.claim-games-container {
    margin-top: 50px;
    .game-table {
        margin-top: 20px;
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
    }
}
.site-admin-container {
    margin-top: 50px;
    .people-table {
        margin-top: 20px;
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
    }
}
.contact-us-container {
    margin-top: 100px;
    .forms {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
    .forms-title {
        font-weight: 600;
    }
}
