
$secondarycolor: rgb(24, 207, 24);

$text-margin: 10px;


@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import "video-react/styles/scss/video-react";

@import './main.scss';
@import './components/appbar.scss';
@import './components/tables.scss';
@import './components/text-field.scss';
@import './components/divs.scss';
@import './components/drawer.scss';

// Pages
@import './pages/home.scss';
@import './pages/game-evaluation.scss';
