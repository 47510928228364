.info-card {
    .text-field {
        
        width: 100%;
        margin-top: $text-margin;
        margin-bottom: $text-margin;
    }
}

.forms{    
    .text-field {
        width: 100%;
        margin-top: $text-margin;
        margin-bottom: $text-margin;
        margin-right: auto;
        margin-left: auto;
    }
}